@font-face {
    font-family: 'Muslimah';
    src: url('https://cdn.shopify.com/s/files/1/0564/0926/0182/files/Muslimah-Light.woff2?v=1734002149') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0564/0926/0182/files/Muslimah-Light.woff?v=1734002149') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muslimah';
    src: url('https://cdn.shopify.com/s/files/1/0564/0926/0182/files/Muslimah-Thin.woff2?v=1734002149') format('woff2'),
        url('https://cdn.shopify.com/s/files/1/0564/0926/0182/files/Muslimah-Thin.woff?v=1734002150') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


